import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Box, Button, TablePagination, TableFooter, ListItemText } from '@mui/material';
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import TablePaginationActions from '../../common/TablePaginationActions';
import { useTranslation } from 'react-i18next';
import { CreateDeliveryOrder, GetOrders } from '../../../api/OmaYritysApi';
import { Order } from '../../../model/OmaXTypes';

const TableCellThin = styled(TableCell)`
  padding: ${tokens.spacing.xxs};
`

const OrderTable: React.FC = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(1);
  const [orders, setOrders] = useState<Order[]>([])

  useEffect(() => {
    GetOrders().then(initialOrders => {
      setOrders(initialOrders.data)
    })
  }, [])

  const getOrderDate = () => {
    let orderDate = new Date()

    orderDate.setDate(orderDate.getDate() - (Math.floor(Math.random() * 4) + 1))

    return orderDate.toLocaleDateString('fi-FI')
  }

  const addDeliveryOrder = async (order: Order) => {
    console.log("Add new delivery order.")
    const newDeliveryOrder = {
      orderNumber: order.id,
      fromAddress: order.fromAddress,
      actualShipmentDatetime: new Date().toISOString(),
      volumeMeasure: order.volumeMeasure,
      volumeUnit: order.volumeUnit,
      weightMeasure: order.weightMeasure,
      weightUnit: order.weightUnit,
      packageType: "0",
      packageBarcode: "",
      eFTIDataId: "",
      carrierParty: "E53ddaaf-ce98-d0b5-6ae7-e57ef42b5479",
      modified: new Date().toISOString()
    }

    try {
      const response = await CreateDeliveryOrder(newDeliveryOrder);
    } catch (error) {
      console.error("Error while creating delivery order:", error)
    }
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{overflow: "auto"}}>
      <TableContainer component={Paper} style={{minWidth: "800px"}}>
        <Table aria-label="simple table">
          <colgroup>
            <col width="15%"/>
            <col width="40%"/>
            <col width="15%"/>
            <col width="20%"/>
            <col width="10%"/>
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCellThin align="left">{t("app.components.OrderTable.product")}</TableCellThin>
              <TableCellThin align="left">{t("app.components.OrderTable.buyer")}</TableCellThin>
              <TableCellThin align="left">{t("app.components.OrderTable.quantity")}</TableCellThin>
              <TableCellThin align="left">{t("app.components.OrderTable.orderDate")}</TableCellThin>
              <TableCellThin align="left">{t("app.components.OrderTable.delivery")}</TableCellThin>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order, i) => (
            <TableRow key={i}>
              <TableCellThin align="left">{order.product}</TableCellThin>
              <TableCellThin align="left">
                <ListItemText primary={order.orderer} secondary="0216:00377006370100100" />
              </TableCellThin>
              <TableCellThin align="left">{order.productPieces}</TableCellThin>
              <TableCellThin align="left">{getOrderDate()}</TableCellThin>
              <TableCellThin align="left"><Button onClick={() => addDeliveryOrder(order)} >{t("app.components.OrderTable.deliverBtn")}</Button></TableCellThin>
            </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination 
                labelRowsPerPage="Rivejä per sivu:"
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default OrderTable;